<template>
  <div class="associated">
    <div class="associated_top">
      <div style="flex: 1"></div>
      <div class="associated_img">
        <img :src="companyinfo.coverImage" alt="" />
        <span style="padding-left: 10px">{{ companyinfo.partnerName }}</span>
      </div>
      <div style="flex: 1" class="account_cycle" @click="beforeAccountDialog(id)">
        <!-- <img
          src="../../../assets/image/Group-1.png"
          alt=""
          style="width: 16px; height: 16px; margin-right;: 10px;vertical-align: top;"
        />
        账号周期：{{ companyinfo.beginTime }}至{{ companyinfo.deadline }}
        <span v-if="companyinfo.prompt" style="color: #fd702b; width: 80px; display: inline-block">
          <img
            src="../../../assets/image/Group.png"
            alt=""
            style="width: 16px; height: 16px; margin-right;: 10px;vertical-align: top;"
          />即将到期</span
        >
        <span v-else style="width: 80px; display: inline-block"></span> -->
      </div>
    </div>
    <div class="switch_tab">
      <div style="padding-left: 20px">
        <el-button type="primary" @click="addaccount()">新增</el-button>
      </div>
      <div class="label_management">
        <el-table
          :data="listData"
          stripe
          @sort-change="changeSort"
          style="width: 100%"
          class="in_table"
          :header-cell-style="{ background: '#F8F9FB', color: '#595959' }"
        >
          <el-table-column label="编号" align="center" prop="id"></el-table-column>

          <el-table-column label="姓名" prop="" align="center">
            <template v-slot="{ row }">
              <span>{{ row.userName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="角色" prop="" align="center">
            <template v-slot="{ row }">
              <span>{{ row.roleName }}</span>
            </template>
          </el-table-column>

          <el-table-column label="手机号" prop="mobile" align="center"></el-table-column>
          <el-table-column label="创建人" prop="userName" align="center"></el-table-column>
          <el-table-column label="是否H5可用" prop="time" align="center">
            <template v-slot="{ row }">
              <el-switch
                @change="notStateHF(row)"
                v-model="row.isHFive"
                :active-text="row.isHFive ? '是' : '否'"
              >
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column label="登录状态" prop="time" align="center">
            <template v-slot="{ row }">
              <el-switch
                @change="notState(row)"
                v-model="row.notState"
                :active-text="row.notState ? '启用' : '禁用'"
              >
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column label="创建时间" prop="time" align="center" sortable="">
            <template v-slot="{ row }">
              <span>{{ row.createTime }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
            <!-- slot-scope="scope" -->
            <template slot-scope="scope">
              <el-button style="color: #146aff" type="text" @click="editphone(scope.row)"
                >编辑
              </el-button>
              <el-button
                v-if="scope.row.notDelete == 0"
                style="color: #fd5469"
                type="text"
                @click="accoutClick(scope.row)"
                >冻结
              </el-button>
              <el-button
                v-if="scope.row.notDelete == 1"
                style="color: #146aff"
                type="text"
                @click="accoutClick(scope.row)"
                >激活
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- <el-dialog
      title="账号周期"
      :visible.sync="accountDialogVisible"
      width="527px"
      class="account_dialog"
      :before-close="afteraccountDialogHandle"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="账号周期" prop="date">
          <el-date-picker
            value-format="yyyy-MM-DD"
            v-model="ruleForm.date"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            required
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>

      <div style="text-align: center; margin-top: 40px">
        <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
        <el-button @click="afteraccountDialogHandle">取 消</el-button>
      </div>
    </el-dialog> -->
    <el-dialog
      title="新增"
      :visible.sync="accountTypeDialogVisible"
      width="50%"
      class="account_dialog"
    >
      <el-form label-width="100px" :model="adminUser" :rules="adminrules" ref="accountTypeDialog">
        <el-form-item label="手机号:" prop="mobile">
          <el-input v-model="adminUser.mobile" placeholder="请输入手机号" />
        </el-form-item>
        <el-form-item label="真实姓名:" prop="userName">
          <el-input v-model="adminUser.userName" placeholder="请输入真实姓名" />
        </el-form-item>
        <el-form-item label="角色:" prop="userRoleId">
          <el-radio-group v-model="adminUser.userRoleId">
            <el-radio v-for="(el, index) in listrole" :key="index" :label="el.id">{{
              el.roleName
            }}</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>

      <div style="text-align: center; margin-top: 40px">
        <el-button type="primary" @click="submitAccountTypeForm('accountTypeDialog')"
          >确 定</el-button
        >
        <el-button @click="accountTypeDialogVisible = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  partnerAccountTwoPage,
  getByPartnerId,
  updatePartnerAccount,
  updateUsers,
  notDelete,
  insertUser,
  notState,
  accoutlistById,
  listRoleGinseng,
  isHFour
} from '@/api/channel'
export default {
  name: 'account',
  computed: {
    id() {
      return this.$route.query.id
    }
  },
  data() {
    return {
      ruleForm: {
        date: null
      },
      rules: {
        date: [{ required: true, message: '请选择账号周期', trigger: 'blur' }]
      },
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      accountDialogVisible: false,
      accountTypeDialogVisible: false,
      accountType: '主账号',
      queryInfo: {
        partnerId: this.$route.query.id,
        pageNum: 1,
        pageSize: 10,
        orders: 1 //排序 默认传1 2->创建时间升序;3->创建时间降序
      },
      listData: [],
      total: 0,
      companyinfo: {},
      adminUser: {
        mobile: '', //	手机号
        notState: true, //状态
        partnerId: '', //合作伙伴编号
        userName: '', //用户名
        userRoleId: '' //用户角色编号
      },
      listrole: [],
      adminrules: {
        userName: [{ required: true, message: '请输入真实姓名', trigger: 'blur' }],
        userRoleId: [{ required: true, message: '请勾选角色', trigger: 'change' }],
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          // 这个只能验证手机号
          // { pattern:/^0{0,1}(13[0-9]|15[7-9]|153|156|18[7-9])[0-9]{8}$/, message: "请输入合法手机号", trigger: "blur" }
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: '请输入合法手机号',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  created() {
    this.getCorrelateUser()
    this.getcompany()
  },
  methods: {
    //查询企业信息回显
    async getcompany() {
      const res = await getByPartnerId({ id: this.id })
      if (res.data.resultCode == 200) {
        this.companyinfo = res.data.data
      }
    },
    //查询系统角色
    async listRoleGinseng() {
      const query = {
        partnerId: this.id
      }
      const res = await listRoleGinseng(query)
      if (res.data.resultCode == 200) {
        this.listrole = res.data.data
      }
    },
    beforeAccountDialog(id) {
      this.currentId = id
      this.accountDialogVisible = true
    },
    //冻结账号
    accoutClick(row) {
      const query = {
        id: row.id
      }
      this.$alert('您是否确认操作？')
        .then(function () {
          return notDelete(query)
        })
        .then(() => {
          this.getCorrelateUser()
          this.$message.success('操作成功')
        })
        .catch(() => {})
    },
    // 是否可登录H5
    notStateHF(row) {
      const query = {
        id: row.id
      }
      this.$alert('您是否确认操作？')
        .then(function () {
          return isHFour(query)
        })
        .then(() => {
          this.getCorrelateUser()
          this.$message.success('操作成功')
        })
        .catch(() => {})
    },
    //账号启用禁用
    notState(row) {
      const query = {
        id: row.id
      }
      this.$alert('您是否确认操作？')
        .then(function () {
          return notState(query)
        })
        .then(() => {
          this.getCorrelateUser()
          this.$message.success('操作成功')
        })
        .catch(() => {})
    },
    async getCorrelateUser() {
      const res = await partnerAccountTwoPage(this.queryInfo)
      if (res.data.resultCode == 200) {
        this.listData = res.data.data.list
        this.listData.forEach((el) => {
          el.notState = !el.notState
        })
        console.log(this.listData)
      }
    },
    submitAccountTypeForm(formName) {
      this.adminUser.partnerId = this.id
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (!this.adminUser.id) {
            insertUser(this.adminUser).then(({ data: res }) => {
              if (res.resultCode === 200) {
                this.getCorrelateUser()
                this.accountTypeDialogVisible = false
              } else {
                this.$message.error(res.message)
              }
            })
          } else {
            updateUsers(this.adminUser).then(({ data: res }) => {
              if (res.resultCode === 200) {
                this.getCorrelateUser()
                this.accountTypeDialogVisible = false
              } else {
                this.$message.error(res.message)
              }
            })
          }
        }
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          updatePartnerAccount({
            beginTime: this.ruleForm.date[0],
            deadline: this.ruleForm.date[1],
            id: this.currentId
          }).then(({ data: res }) => {
            if (res.resultCode === 200) {
              this.afteraccountDialogHandle()
              this.getCorrelateUser()
              this.getByPartnerId()
            } else {
              this.message.error(res.message)
            }
          })
        } else {
          return false
        }
      })
    },
    //新增账号
    addaccount() {
      this.accountTypeDialogVisible = true
      this.listRoleGinseng()
      this.adminUser = {
        mobile: '', //	手机号
        notState: true, //状态
        partnerId: '', //合作伙伴编号
        userName: '', //用户名
        userRoleId: '' //用户角色编号
      }
    },
    //编辑
    async editphone(row) {
      this.accountTypeDialogVisible = true
      this.listRoleGinseng()
      await this.accoutlistById(row.id)
    },
    //账号详情
    async accoutlistById(id) {
      const { data: res } = await accoutlistById({ id })
      if (res.resultCode == 200) {
        this.adminUser = res.data
      }
    },
    afteraccountDialogHandle() {
      this.ruleForm.date = null
      this.accountDialogVisible = false
    },
    //排序
    changeSort(val) {
      //val里面返回prop和order
      if (val.prop == 'time') {
        if (val.order == 'ascending') {
          //updateAt列升序时

          this.queryInfo.orderByParam = 'r.create_time ASC'
          this.getCorrelateUser()
        } else if (val.order == 'descending') {
          //updateAt列降序时

          this.queryInfo.orderByParam = 'r.create_time DESC'
          this.getCorrelateUser()
        } else {
          //updateAt列不排序时
          this.queryInfo.orderByParam = ''
          this.getCorrelateUser()
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>
::v-deep .el_upload .el-form-item {
  margin-bottom: 0px;
}
::v-deep .name_form .el-input__suffix {
  position: absolute;
  height: 100%;
  right: -54px;
  top: 0;
  text-align: center;
  color: #c0c4cc;
  transition: all 0.3s;
  pointer-events: none;
}
::v-deep .el-step__title {
  font-size: 14px;
  line-height: 38px;
}

::v-deep .name_form .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 41px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 556px;
}
::v-deep .name_form .el-input {
  position: relative;
  font-size: 14px;
  display: inline-block;
  width: 496px;
}

.associated {
  background: #ffffff;
  .img_commer {
    display: flex;
    border: 1px solid #146aff;
    border-radius: 39px;
    .name {
      font-size: 12px;
      line-height: 20px;
      color: #146aff;
    }
    .color_img {
      background: #146aff;
      height: 20px;
      width: 25px;
      border-radius: 20px;
      position: relative;
      left: -10px;
      img {
        width: 13px;
        height: 13px;
        position: relative;
        left: 5px;
        top: 2px;
      }
    }
  }
  .img_commers {
    display: flex;
    border: 1px solid #ff9c00;
    border-radius: 39px;
    margin: 0px 10px;
    .name {
      font-size: 12px;
      line-height: 20px;
      color: #ff9c00;
    }
    .color_img {
      background: #ff9c00;
      height: 20px;
      width: 25px;
      border-radius: 20px;
      position: relative;
      left: -10px;
      img {
        width: 13px;
        height: 13px;
        position: relative;
        left: 5px;
        top: 2px;
      }
    }
  }
  .associated_top {
    background: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // flex-direction: column;
    // padding: 30px 0px 0px 0px;
    .account_cycle {
      text-align: right;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;

      /* 灰色字体 */

      color: #969495;
    }
    .associated_img {
      display: flex;
      align-items: center;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;

      color: #151515;
      img {
        width: 67px;
        height: 54px;
        border-radius: 5px;
      }
    }
  }
  .switch_tab {
    background: #ffffff;
    margin-top: 20px;

    .essential_information {
      padding: 30px 40px;
      .general_input {
        width: 845px;
        border: 1px solid #d4d4d4;
        padding: 14px 16px;

        .input_flex {
          display: flex;
          justify-content: space-between;
          padding: 7px 0px;
          span {
            padding-left: 15px;
            font-size: 20px;
            cursor: pointer;
          }
        }
      }
      .img_tips {
        position: relative;
        top: -10px;
        color: #6ba1ff;
      }

      h4 {
        color: #146aff;
      }
      .dialog-footer {
        display: flex;
        justify-content: center;
      }
    }
  }

  .label_management {
    padding: 10px 10px;

    .new_page {
      margin: 20px auto;
      width: 100%;
      text-align: center;
    }

    .input-with {
      width: 100%;
      overflow: hidden;
      padding: 20px 0px;

      .input-with-select {
        width: 400px;
        float: left;
      }

      .seach {
        background-color: #448aff;
        color: #fff;
        border-radius: 0;
        border: 1px solid #448aff;
      }
    }
  }
}
</style>
